import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PricingBanner = () => {
    return (
        <>
            <div className='flex flex-col items-center justify-center py-8 mt-20 text-3xl text-white md:py-0 md:flex-row bg-rickshaw-blue md:h-36'>
                    <p>Start with your</p>
                    <p className='mx-4 my-4 font-bold md:my-0'>1,000</p>
                    <p>free events</p>
            </div>

            <div className='flex flex-col items-center justify-center text-xl font-medium h-36 md:flex-row'>
                <Link to='/pricing' className='text-rickshaw-blue md:mr-2.5 no-underline my-4 md:my-0'>
                    See our Pricing
                    <FontAwesomeIcon icon={['far', 'arrow-right']} className='ml-2.5' />
                </Link>
                <span className='mx-8 mb-4 font-bold text-rickshaw-lightgrey md:mb-0'>OR</span>
                <a href='https://confirmsubscription.com/h/d/7C7BF2C5C5C65206' className='text-rickshaw-blue md:mr-2.5 no-underline'>
                    Sign up for early access
                    <FontAwesomeIcon icon={['far', 'arrow-right']} className='ml-2.5' />
                </a>
            </div>
        </>
    );
};
export default PricingBanner;
